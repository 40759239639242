import React from "react";
import styled, { css } from "styled-components";

import StyledButton from "../shared/StyledButton";

import { media } from "utils/Media";

const StyledButtonCustom = styled(StyledButton)`
  max-width: 320px;
`;

const WhiteBox = styled.div`
  width: 100%;
  background: linear-gradient(
    to bottom,
    ${(props) => props.theme.colors.red},
    ${(props) => props.theme.colors.yellow}
  );
  padding: 3px;
  margin: 3rem 0 2rem;
  ${(props) =>
    props.narrow &&
    css`
      max-width: 900px;
      margin-right: auto;
      margin-left: auto;
    `}
  .helper {
    background: ${(props) => props.theme.colors.white};
    padding: 1rem;
    position: relative;
    color: ${(props) => props.theme.colors.black};

    @media ${media.sm} {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    h3 {
      color: ${(props) => props.theme.colors.black};
    }

    .items {
      @media ${media.sm} {
        width: 50%;
      }
    }
  }
  ${(props) =>
    props.$smaller &&
    css`
      max-width: 500px;
      margin: 2rem auto;
    `}

  .inner {
    background: #000;
    color: #fff;
    padding-bottom: 2rem;
    p,
    h3 {
      color: #fff;
    }
    .svg-wrapper {
      svg {
        width: 550px;
      }
    }
    .img-wrapper {
      max-width: 550px;
      width: 90%;
      margin: 2rem auto 2rem auto;

      background: linear-gradient(
        to bottom,
        ${(props) => props.theme.colors.red},
        ${(props) => props.theme.colors.yellow}
      );
      padding: 3px;
    }
    .wrapper {
      img {
      }
    }
    @media ${media.md} {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
`;

const WhiteTicketBox = styled(WhiteBox)`
/* ${props => props.white && css`
width: 100%;
  .inner {
    background: white important!
  }
`} */

${(props) =>
    props.white &&
    css`
    /* background: white!important; */
      .inner {
        background: white!important;
      }
    `}
  .helper {
    padding: 1rem 0.5rem;

    margin: 0;
    height: 100%;
    h3 {
      font-size: 22px;
    }
    section {
      margin: 0 0 1.5rem 0;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
    p {
      margin: 0;
      padding: 0;
      font-size: 16px;
    }
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;

const Box = styled.div`
  width: 100%;
  background: linear-gradient(
    to bottom,
    ${(props) => props.theme.colors.red},
    ${(props) => props.theme.colors.yellow}
  );
  padding: 3px;
  margin: 3rem 0 2rem;
  ${(props) =>
    props.narrow &&
    css`
      max-width: 900px;
      margin-right: auto;
      margin-left: auto;
    `}
  .helper {
    background: ${(props) => props.theme.colors.black};
    padding: 1rem;
    position: relative;
    color: ${(props) => props.theme.colors.white};

    @media ${media.sm} {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    h3 {
      color: ${(props) => props.theme.colors.white};
    }

    .items {
      @media ${media.sm} {
        width: 50%;
      }
    }
  }
  ${(props) =>
    props.$smaller &&
    css`
      max-width: 500px;
      margin: 2rem auto;
    `}

  .inner {
    background: #000;
    color: #fff;
    padding-bottom: 2rem;
    p,
    h3 {
      color: #fff;
    }
    .svg-wrapper {
      svg {
        width: 550px;
      }
    }
    .img-wrapper {
      max-width: 550px;
      width: 90%;
      margin: 2rem auto 2rem auto;

      background: linear-gradient(
        to bottom,
        ${(props) => props.theme.colors.red},
        ${(props) => props.theme.colors.yellow}
      );
      padding: 3px;
    }
    .wrapper {
      img {
      }
    }
    @media ${media.md} {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
`;

const NewTicketBox = styled(Box)`
/* ${props => props.white && css`
width: 100%;
  .inner {
    background: white important!
  }
`} */

${(props) =>
    props.white &&
    css`
    /* background: white!important; */
      .inner {
        background: white!important;
      }
    `}
  .helper {
    padding: 1rem 0.5rem;

    margin: 0;
    height: 100%;
    h3 {
      font-size: 22px;
    }
    section {
      margin: 0 0 1.5rem 0;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
    p {
      margin: 0;
      padding: 0;
      font-size: 16px;
    }
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;

const TicketFlowsStyled = styled.section`
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 3rem 0 0 0;
  text-align: center;
  h2 {
    padding: 2rem 0;
    font-size: 32px;
    @media ${media.md} {
      font-size: 46px;
    }
  }
  .wrapper {
    width: 90%;
    margin: 0 auto;
  }
  @media ${media.md} {
    .wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 1rem;
    }
  }
`;

export default function TicketFlows() {
  return (
    <TicketFlowsStyled>
      <h2>Great Scott! GET YOUR TICKETS YESTERDAY! </h2>
      {/* <h2>CHRISTMAS AVAILABILITY </h2> */}
      {/* <h2>Great Scott! Grab Your Ticket Deals Now!</h2> */}
      {/* <div className="wrapper">
        <WhiteTicketBox $smaller white>
          <div className="helper">
            <div className="item pb-3">
              <h3 style={{ paddingBottom: "0.5rem" }}>
                BOOK FAST TO AVOID DISAPPOINTMENT
              </h3>
              <section>
                <p>20TH DEC 7:30 PM</p>
                <p>21ST DEC 2:30 PM</p>
                <p>27TH DEC 2:30 &amp; 7:30 PM</p>
                <p>28TH DEC 2:30 &amp; 7:30 PM</p>
                <p>29TH DEC 2:30 PM</p>
              </section>
              <StyledButtonCustom
                target="_blank"
                rel=" noopener"
                href="https://ticketing.lwtheatres.co.uk/event/218/?date=2024-12"
              >
                Book Now
              </StyledButtonCustom>
            </div>
          </div>
        </WhiteTicketBox>
        <WhiteTicketBox $smaller white>
          <div className="helper">
            <div className="item pb-3">
              <h3 style={{ paddingBottom: "0.5rem" }}>SELLING FAST</h3>
              <section>
                <p>16TH DEC 7:30 PM</p>
                <p>19TH DEC 2:30 PM</p>
                <p>21ST DEC 7:30 PM</p>
                <p>22ND DEC 2:30 &amp; 7:30 PM</p>
                <p>23RD DEC 2:30 PM</p>
                <p>29TH DEC 7:30 PM</p>
                <p>30TH DEC 2:30 &amp; 7:30 PM</p>
                <p>31ST DEC 2:30 PM</p>
                <p>4TH JAN 2:30 PM</p>
              </section>
              <StyledButtonCustom
                target="_blank"
                rel=" noopener"
                href="https://ticketing.lwtheatres.co.uk/event/218/?date=2024-12"
              >
                Book Now
              </StyledButtonCustom>
            </div>
          </div>
        </WhiteTicketBox>
        <WhiteTicketBox $smaller white>
          <div className="helper">
            <div className="item pb-3">
              <h3 style={{ paddingBottom: "0.5rem" }}>BEST AVAILABILITY </h3>
              <section>
                <p>16TH DEC 2:30 PM</p>
                <p>23RD DEC 7:30 PM</p>
                <p>24TH DEC 2:30 & 7:30 PM</p>
                <p>2ND JAN 2:30 &amp; 7:30 PM</p>
                <p>3RD JAN 2:30 & 7:30 PM</p>
                <p>4TH JAN 7:30 PM</p>
              </section>
              <StyledButtonCustom
                target="_blank"
                rel=" noopener"
                href="https://ticketing.lwtheatres.co.uk/event/218/?date=2024-12"
              >
                Book Now
              </StyledButtonCustom>
            </div>
          </div>
        </WhiteTicketBox>
      </div> */}
      <div className="wrapper">
        <NewTicketBox $smaller>
          <div className="helper">
            <div className="item pb-3">
              <h3 style={{ paddingBottom: "0.5rem" }}>
                WEEKEND AVAILABILITY FROM £19.85
              </h3>
              <StyledButtonCustom
                target="_blank"
                rel=" noopener"
                href="https://ticketing.lwtheatres.co.uk/event/218/?PROMO=BTTFWEEKEND"
              >
                Book Now
              </StyledButtonCustom>
            </div>
          </div>
        </NewTicketBox>
        <NewTicketBox $smaller>
          <div className="helper">
            <div className="item pb-3">
              <h3 style={{ paddingBottom: "0.5rem" }}>
                SEE US THIS MONTH FOR BEST AVAILABILITY AND PRICES
              </h3>
              <StyledButtonCustom
                target="_blank"
                rel=" noopener"
                href="https://ticketing.lwtheatres.co.uk/event/218/?PROMO=BTTFSEPT"
              >
                Book Now
              </StyledButtonCustom>
            </div>
          </div>
        </NewTicketBox>
        <NewTicketBox $smaller>
          <div className="helper">
            <div className="item pb-3">
              <h3 style={{ paddingBottom: "0.5rem" }}>
                SAVE WHEN YOU BOOK 6 OR MORE TICKETS | GREAT SEATS FROM £50
              </h3>
              <StyledButtonCustom
                target="_blank"
                rel=" noopener"
                href="https://ticketing.lwtheatres.co.uk/event/218?PROMO=BTTFSIX"
              >
                Book Now
              </StyledButtonCustom>
            </div>
          </div>
        </NewTicketBox>
      </div>
    </TicketFlowsStyled>
  );
}
